<template>
    <div class="process-page">
        <el-form label-width="100px" label-position="left" v-for="(item, index) in processData.processForm"
            :key="item.id" :model="processData" :ref="item.formRef">
            <div class="content-wrap" style="background-color: #fff; margin: 20px; border-radius: 10px">
                <div class="content-left">
                    <el-form-item :prop="'processForm.' + index + '.processTitleName'" :rules="[
                        {
                            required: true,
                            message: `流程${index + 1}标题名称不能为空`,
                            trigger: 'blur'
                        }
                    ]">
                        <div>流程{{ index + 1 }}标题名称：</div>
                        <el-input size="small" style="width: 500px" maxlength="16" v-model="item.processTitleName"
                            :placeholder="`请输入流程标题${index + 1}名称内容`" v-if="item.isProcessModify"></el-input>
                        <div style="width: 500px" v-else>{{ item.processTitleName }}</div>
                    </el-form-item>
                    <el-form-item :prop="'processForm.' + index + '.processDescCopywrit'" :rules="[
                        {
                            required: true,
                            message: `流程${index + 1}描述文案不能为空`,
                            trigger: 'blur'
                        }
                    ]">
                        <div>流程{{ index + 1 }}描述文案：</div>
                        <el-input size="small" style="width: 500px" maxlength="100" v-model="item.processDescCopywrit"
                            :placeholder="`请输入流程${index + 1}描述文案内容`" v-if="item.isProcessModify"></el-input>
                        <div style="width: 500px" v-else>{{ item.processDescCopywrit }}</div>
                    </el-form-item>
                </div>
                <div class="content-right" @click="handleSaveProcessBtn(item)" v-if="item.isProcessModify">
                    <el-button type="text">保存</el-button>
                </div>
                <div class="content-right" @click="handleModifyProcessBtn(item)" v-else>
                    <i class="el-icon-edit-outline size-icon"></i>
                    修改
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { getInProcessData, inProcessSaveModifyAction } from '@/common/api/contentManage/inProcess.js';
export default {
    data() {
        return {
            processData: {
                processForm: [
                    {
                        // 流程1标题名称
                        processTitleName: '',
                        // 流程1描述文案
                        processDescCopywrit: ''
                    }
                ]
            }
        };
    },
    created() {
        this.queryInProcessData();
    },
    methods: {
        // 查询入驻流程数据
        queryInProcessData() {
            getInProcessData()
                .then(res => {
                    let { code, result } = res;
                    if (code === 200) {
                        let newResult = result.map(item => {
                            return {
                                id: item.id,
                                processTitleName: item.main_title,
                                processDescCopywrit: item.describe,
                                isProcessModify: false,
                                formRef: 'formref' + item.id
                            };
                        });
                        this.processData.processForm = newResult;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理修改按钮
        handleModifyProcessBtn(item) {
            item.isProcessModify = true;
        },
        // 处理保存按钮
        handleSaveProcessBtn(item) {
            let ref = item.formRef;
            this.$refs[ref].validate(valid => {
                if (valid) {
                    let params = {
                        id: item.id,
                        main_title: item.processTitleName,
                        describe: item.processDescCopywrit
                    };
                    inProcessSaveModifyAction(params)
                        .then(res => {
                            let { code, msg } = res;
                            if (code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: `${msg}`,
                                    center: true
                                });
                                this.queryInProcessData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.process-page {
    box-sizing: border-box;
    // height: 100%;
    margin: 0 -20px 20px;
    background-color: #f7f8fa;

    .content-wrap {
        &::after {
            clear: both;
            display: block;
            content: '';
            height: 0;
            visibility: hidden;
        }

        :deep(.el-form-item__content) {
            margin: 0 0 0 15px !important;
        }

        .content-left {
            float: left;
        }

        .content-right {
            float: right;
            margin: 5px 15px 20px 0;
            cursor: pointer;

            .size-icon {
                position: relative;
                top: 3px;
                font-size: 24px;
            }
        }
    }
}
</style>
