import $http from '@/utils/http.js';

/**
 * 入驻流程数据
 * @param data
 */
export function getInProcessData(data) {
    return $http.get('/Content/enter_course_index', data, 'loadingDiv');
}

/**
 * 入驻流程保存修改操作
 * @param data
 */
export function inProcessSaveModifyAction(data) {
    return $http.post('/Content/enter_course_save', data, 'loadingDiv');
}
